import { useQuery } from "react-query";
import { fetchCorrectPicks, fetchCurrentPick, fetchNextPicks, fetchPlayerPicks, setPlayerPick } from "../api/picks";
import { Loading } from "../components/elements/Loading";
import styled from "styled-components";
import { CorrectPicks, CurrentPick, NextPicks, PlayerPicks } from "../../../@types";
import { eventIconIdMap } from "../components/visuals/GameIcons";
import { useEffect, useMemo, useState } from "react";
import { Select } from "../components/elements/Select";
import { Panel } from "../components/visuals/Panel";
import { CastawayIcon } from "../components/visuals/CastawayIcon";
import { fetchAiredEpisodes } from "../api/seasons";

export function Picks({ tribeId }: { tribeId: number }) {
  const { data } = useQuery("picks", () => fetchPlayerPicks(tribeId))
  const { data: airedEpisodes } = useQuery('episodes', () => fetchAiredEpisodes(tribeId));
  const { data: correctPicks } = useQuery('correctPicks', () => fetchCorrectPicks(tribeId));
  const groupedData = useMemo(() => groupEpisodeData(data), [data])
  if (data === undefined || groupedData === undefined || correctPicks === undefined || airedEpisodes === undefined) return <Loading />;

  return (
    <PicksLayout>
      <NextPicksTile tribeId={tribeId} />
      {data.length > 0 && <p style={{ textAlign: 'center', width: '300px' }}>Correct picks are shown in <span style={{ color: '#dab202', textAlign: 'center', textShadow: '1px 1px 3px black' }}>yellow</span> under your pick.</p>}
      {Object.entries(groupedData).reverse().map((episode, i) => <EpisodeTile key={i} episodeNumber={Number(episode[0])} data={episode[1]} correctPicks={correctPicks} />)}
      {/* {Object.entries(airedEpisodes).map(episode => {
        return <EpisodeTile episodeNumber={episode[1].season_episode_number} data={groupedData[episode[1].season_episode_number]} correctPicks={correctPicks} />
      })} */}
    </PicksLayout>
  )
}


function EpisodeTile({ episodeNumber, data, correctPicks }: { episodeNumber: number, data?: PlayerPicks[], correctPicks: CorrectPicks[] }) {
  return (
    <EpisodeTileLayout>
      <EpisodeDetails>
        <EpisodeNumber>Episode {episodeNumber}</EpisodeNumber>
        <TotalScore>{data !== undefined ? data.reduce((sum, event) => sum + (event.correct ? event.score : 0), 0) : 'N/A'}</TotalScore>
      </EpisodeDetails>
      {data !== undefined && data.map((event, i) => {
        const correctPick = correctPicks.find(pick => pick.event_id === event.event_id && pick.season_episode_number === episodeNumber);
        return (
          <EventElement key={i}>
            <EventName style={{ textAlign: 'left' }}><span>{event.event_name}:</span> {event.event_description}</EventName>
            <CastawayDetailsLayout >
              <CastawayIcon castawayId={event.castaway_id} style={{ gridRow: '1 / -1', gridColumn: 1 }} />
              <CastawayName>{event.castaway_name}</CastawayName>
              <CorrectCastawayName>{correctPick?.type === 'tribe' ? 'Tribe: ' :''}{correctPick?.names.replaceAll(',', ', ') || 'Data not yet available'}</CorrectCastawayName>
            </CastawayDetailsLayout>
            <Score correct={event.correct}>{event.correct ? `+${event.score}` : '---'}</Score>
          </EventElement>
        )
      }
      )}
      {data === undefined && <EventElement>No picks were made for this episode</EventElement>}
    </EpisodeTileLayout>
  )
}

export function NextPicksTile({ tribeId }: { tribeId: number }) {
  const { data } = useQuery("nextPicks", () => fetchNextPicks(tribeId));
  const groupedData = useMemo(() => groupEventData(data), [data]);
  const seasonEpisodeNumber = useMemo(() => data !== undefined ? data[0]?.season_episode_number : 0, [data])
  const { data: currentPicks } = useQuery([seasonEpisodeNumber, tribeId], () => fetchCurrentPick(tribeId, seasonEpisodeNumber))

  if (data === false) return <></>
  if (data === undefined || groupedData === undefined || seasonEpisodeNumber === undefined || currentPicks === undefined) return <Loading />

  return (
    <>
      <h4 style={{ textAlign: 'center', fontWeight: '400' }}>Make your picks for the next episode!</h4>
      <EpisodeTileLayout>
        <EpisodeNumber style={{ padding: '10px' }}>Episode {seasonEpisodeNumber}</EpisodeNumber>
        {Object.entries(groupedData).map((event) =>
          <NextPickEventElement key={event[0]}>
            <EventName><span>{event[1].name}:</span> {event[1].description}</EventName>
            <ChooseCastaway
              currentPicks={currentPicks}
              seasonEpisodeId={seasonEpisodeNumber}
              eventId={Number(event[0])}
              tribeId={tribeId}
              castaways={event[1].castaways}
            />
          </NextPickEventElement>
        )}
      </EpisodeTileLayout>
    </>
  )
}

type ChooseCastawayProps = {
  seasonEpisodeId: number,
  eventId: number,
  tribeId: number,
  castaways: { name: string; id: number }[],
  currentPicks?: CurrentPick[],
}
function ChooseCastaway({ castaways, eventId, seasonEpisodeId, tribeId, currentPicks }: ChooseCastawayProps) {
  if (castaways === undefined || castaways[0] === undefined) return <></>;
  const [currentPick, setCurrentPick] = useState<number>(castaways[0]?.id);
  useEffect(function updateCurrentPick() {
    if (currentPicks !== undefined)
      setCurrentPick(currentPicks.find(event => event.event_id === eventId)?.castaway_id || 0);
  }, [currentPicks])

  return (
    <Select
      options={[...castaways.map(castaway => ({ label: castaway.name, value: castaway.id })), { label: 'Choose a castaway', value: 0 }]}
      onChange={(value: number) => { setPlayerPick(eventId, value, seasonEpisodeId, tribeId); setCurrentPick && setCurrentPick(value); }}
      value={currentPick}
      style={{ gridColumn: '1 / -1', gridRow: 2 }}
      backgroundColor={`${currentPick === 0 ? '#7880c8' : undefined}`}
    />
  )
}

function groupEpisodeData(data: PlayerPicks[] | undefined): Record<number, PlayerPicks[]> | undefined {
  if (data === undefined) return;
  const final = data.reduce((result, row) => {
    if (result[row.season_episode_number] === undefined)
      result[row.season_episode_number] = [];

    result[row.season_episode_number].push({ ...row })
    return result;
  }, {})
  return final
}

type GroupedEventData = {
  name: string;
  description: string;
  castaways: {
    id: number;
    name: string;
  }[]
};

function groupEventData(data: NextPicks[] | undefined | false): Record<number, GroupedEventData> | undefined {
  if (data === false) return undefined;
  const final = data?.reduce((result, cur) => {
    if (result[cur.event_id] === undefined)
      result[cur.event_id] = { name: cur.event_name, castaways: [], description: cur.event_description }
    result[cur.event_id].castaways.push({ id: cur.castaway_id, name: cur.castaway_name })
    return result
  }, {});
  return final;
}





const PicksLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`
const EpisodeTileLayout = styled(Panel)`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 0;

  :last-of-type {
    margin-bottom: 3rem;
  }
`
const EpisodeDetails = styled.div`
  background-color: ${props => props.theme.panel.background};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px 10px;
`
const EpisodeNumber = styled.h3`
  color: ${props => props.theme.panel.color};
  font-size: 22px;
  font-weight: 500;
  width: 100%;
  margin: 0;
`
const EventElement = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 10px;
  background: ${props => props.theme.panel.secondaryBackground};
  border-bottom: 2px solid ${props => props.theme.colors.main};
  color: ${props => props.theme.panel.color};

  :last-of-type {
      border-bottom: none;
      border-radius: 0 0 10px 10px;
  }
`
const NextPickEventElement = styled(EventElement)`
  grid-template-rows: 1fr 1fr;
`
const CastawayName = styled.h4`
  color: ${props => props.theme.panel.color};
  font-size: 20px;
  letter-spacing: 2px;
  grid-column: 2;
`
const CorrectCastawayName = styled.h5`
  color: ${props => props.theme.panel.color};
  font-size: 15px;
  letter-spacing: 2px;
  grid-column: 2;
  color: #dab202;
`
const CastawayDetailsLayout = styled.div`
  grid-column: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 1rem;
  width: 100%;
  align-items: center;
  justify-items: center;
  padding: 10px;
  text-align: center;

`
const Score = styled.h4<{ correct: boolean }>`
  font-size: 22px;
  color: ${props => props.theme.panel.color};
  grid-column: 2;
  justify-self: end;
`
const TotalScore = styled.h3`
  font-size: 26px;
  color: ${props => props.theme.panel.color};
  margin-left: auto;
`
const EventIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  /* border-right: 2px solid ${props => props.theme.colors.main}; */
  height: 100%;
  img {
      position: relative;
      top: -1.5px;
  }
`
const EventName = styled.p`
  grid-row: 1;
  grid-column: 1 / -1;
  font-size: 0.75rem;
  justify-self: start;
  margin-left: 10px;

  span {
    color: #000000;
  }
`