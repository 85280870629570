import styled from "styled-components"
import { TribalTriumphLogo } from "./TribalTriumphLogo"
import { useNavigate } from "react-router-dom"
import { HamburgerMenu } from "../elements/HamburgerMenu"
import { useState } from "react"
import { SideMenu } from "../elements/SideMenu"

export function PageHeader({ title, menu, subtitle }: { title: string, subtitle?: boolean, menu?: boolean }) {
  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate()

  return (
    <>
      <Header>
        <HiddenButton style={{ justifySelf: 'start' }} onClick={() => navigate('/home')}>
          <TribalTriumphLogo size="small" style={{ cursor: "pointer" }} />
        </HiddenButton>
        <Title $subtitle={subtitle || false}>{title}</Title>
        <HamburgerMenu style={{ justifySelf: 'end' }} open={sideMenuOpen} onClick={() => setSideMenuOpen(open => !open)} />
      </Header>
      <SideMenu open={sideMenuOpen} onClose={() => setSideMenuOpen(false)}/>
    </>
  )
}


const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    justify-items: center;
    padding: 15px 5% 3px 5%;
`
const Title = styled.h1<{ $subtitle: boolean }>`
    font-size: ${props => props.$subtitle ? '1rem' : '1.4rem'}
`
const HiddenButton = styled.button`
    background-color: rgba(0,0,0,0);
    border: none;
    margin: 0;
    padding: 0;
`