import { CSSProperties, ReactFragment } from "react";
import styled from "styled-components";

export interface ButtonProps {
    onClick?: () => void;
    disabled?: boolean;
    children?: ReactFragment;
    style?: CSSProperties;
}

export function Button({ onClick, children, style, disabled }: ButtonProps) {

    return <StyledButton style={style} disabled={disabled || false} onClick={onClick}>{children}</StyledButton>
}

const StyledButton = styled.button`
  background-color:${props => props.theme.colors.accent};
  border: none;
  color: #FFF;
  font-size: 25px;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  padding: 5px;
  
  :disabled {
    opacity: 0.7;
    background-color: #cdcdcd; 
  }
`