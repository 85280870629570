import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

export type ModalProps = {
    open: boolean;
    onClose?: () => void;
    onSubmit?: () => void;
    children?: ReactNode;
    style?: CSSProperties;
}
export function Modal({ open, children, style, onClose, onSubmit }: ModalProps) {

    if (!open) return <></>

    return (
        <>
            <ModalFrost onClick={onClose}></ModalFrost>
            <ModalBody style={style}>
                {children}
            </ModalBody>
        </>
    );
}

const ModalBody = styled.div`
    background-color: ${props => props.theme.modal.background};
    color: ${props => props.theme.modal.color};
    padding: 20px;
    border-radius: 10px;
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 99;
`
const ModalFrost = styled.div`
    background-color: ${props => props.theme.modal.frost};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
`