import { APIResponse, TribeData, TribePlayerData, TribesData } from "../../../@types.d";

const URL = import.meta.env.VITE_API_URL;

export async function fetchPlayersTribes() {
    const requestOptions: RequestInit = {
        method: 'GET',
        redirect: 'follow',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
        },
    };

    const result = await (await fetch(URL + '/tribes/playersTribes', requestOptions)).json() as APIResponse;

    return result.data as TribesData[];
}

export async function createNewTribe(tribeName: string, seasonId: number) {
    const requestOptions: RequestInit = {
        method: 'POST',
        redirect: 'follow',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
        },
        body: JSON.stringify({ tribeName, seasonId })
    };

    const res = await (await fetch(URL + '/tribes/createTribe', requestOptions)).json() as APIResponse;
    return res.success;
}

export async function fetchPlayerTribeData(tribeId: string) {
    const url = URL + '/tribes/getTribeData/players'
    const requestOptions: RequestInit = {
        method: 'POST',
        redirect: 'follow',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
        },
        body: JSON.stringify({ tribeId })
    };

    const res = await (await fetch(url, requestOptions)).json() as APIResponse;
    if (res.success) return res.data as TribePlayerData[];
    throw Error("Unable to fetch player data");
}

export async function fetchTribeData(tribeIds: string[]) {
    const requestOptions: RequestInit = {
        method: 'POST',
        redirect: 'follow',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
        },
        body: JSON.stringify({ tribeIds: tribeIds.join(',') })
    };

    const res = await (await fetch(URL + '/tribes/getTribeData', requestOptions)).json() as APIResponse;
    if (res.success) return res.data as TribeData[];
    throw Error("Unable to fetch player data");
}

export async function fetchTribeInviteLink(tribeId: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        redirect: 'follow',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
        },
    };

    const res = await (await fetch(URL + `/tribes/${tribeId}/inviteLink`, requestOptions)).json() as APIResponse;
    if (res.success) return res.data as string;
    throw Error("Invalid link")
}