import React from 'react';
import { useTheme } from 'styled-components';
// import {ReactComponent as TribalTriumphSVG} from '../../assets/icons/share-arrow.svg'


export function TribalTriumphLogo(props: { size: 'small' | 'medium' | 'large'; style?: React.CSSProperties }) {
  const { size, style } = props;
  const theme = useTheme()

  const imgSize = {
    small: '34px',
    medium: '75px',
    large: '200px',
  }[size];

  return (
    <div style={{ width: imgSize, ...style }}>
      <svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.83 261.04" fill={theme.colors.main}>
        <g id="Layer_2-2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1-2">
            <g>
              <path className="cls-11" d="m109.53,201.88c-7.02,30.04-28.07,33.04,0,54.06,28.07-21.03,7.02-24.02,0-54.06Zm-17.54,18c-3.51,9.01-10.53,15.06-10.53,24.08s14.03,14.99,24.56,14.99c-21.05-15.02-14.03-24.05-14.03-39.07h0Zm35.08,0c0,15.02,7.02,24.05-14.03,39.07,10.53,0,24.56-5.98,24.56-14.99s-7.02-15.07-10.53-24.08h0Z" />
              <g id="layer1">
                <ellipse id="path4653" className="cls-11" cx="64.96" cy="49.12" rx="8.27" ry="9.38" />
                <ellipse id="ellipse4655" className="cls-11" cx="57" cy="32.18" rx="8.27" ry="9.38" />
                <g id="g4972">
                  <path id="path4515" className="cls-3" d="m98.3,64.69l3.42-24.93,4.28,35.61,2.57-25.82,5.13,24.04-3.42-44.51-13.69,5.34s1.71,30.27,1.71,30.27Z" />
                  <path id="path4526-6" className="cls-4" d="m100.09,26.3c4.42-11.17,12.01-22.61,23.06-25.11,11.05-2.5,23.95,1.71,32.4,8.56,8.46,6.85,17.61,24.01,17.61,24.01,0,0-15.24-9.35-23.44-12.09s-19.69-8.68-30.09-4.24c-10.4,4.44-25.09,36.61-25.09,36.61,0,0,1.13-16.57,5.55-27.73h0Z" />
                  <path id="path4528-0" className="cls-4" d="m94.54,54.03c6.48-14.13,12.14-30.05,24.21-39.42,13.36-5.65,24.06-4.39,32.29,3.62l22.12,15.52c-16.19-3.62-30.71-12.55-49.17-8.93-13.29,2.55-19.72,16.52-29.45,29.21" />
                  <path id="path4520-9" className="cls-7" d="m154.34,38.58c-2.91-13.6-7.9-27.54-15.18-30.59-7.27-3.04-15.77,2.08-21.33,10.43-5.57,8.35-11.59,29.25-11.59,29.25,0,0,10.03-11.39,15.43-14.73s12.96-10.57,19.81-5.17c6.85,5.41,16.52,44.59,16.52,44.59,0,0-.75-20.18-3.65-33.78h-.01Z" />
                  <path id="path4522-7" className="cls-7" d="m157.99,72.36c-4.26-17.22-7.99-36.6-15.94-48.01-8.8-6.89-15.84-5.34-21.26,4.4l-14.56,18.91c10.66-4.41,20.22-15.29,32.37-10.88,8.75,3.1,12.98,20.12,19.39,35.58" />
                  <path id="path4482-5" className="cls-6" d="m7.43,259.8c1.73-11.61,13.68-24.89,17.07-36.09,23.39-64.12,40.18-131.35,81.74-185.95l8,.52c-44.45,71.81-55.77,148.31-61.92,222.18l-44.88-.66h0Z" />
                  <path id="path4484-9" className="cls-2" d="m82.09,26.99c-11.57-2.18-26.01-1.39-37.1,8.06-11.09,9.45-19.05,24.65-21.39,37.02s1.95,31.46,1.95,31.46c0,0,6.33-20.53,11.34-30.23,5.01-9.71,10.56-24.53,22.46-32.2,11.91-7.67,46.88-3.33,46.88-3.33,0,0-12.57-8.58-24.14-10.77h0Z" />
                  <path id="path4517" className="cls-8" d="m105.17,76.4l-4.38-24.13-5.47,34.48-3.28-24.99-6.56,23.27,4.38-43.09,17.51,5.17-2.19,29.3h-.01Z" />
                  <path id="path4490-7" className="cls-2" d="m106.23,37.75c-15.39-1.88-31.33-5.61-48.11.82-15.26,9.88-23.42,21.19-24.76,34l-7.82,30.94c11.15-18.11,17.16-37.68,35.3-53.79,13.02-11.63,28.25-9.8,45.38-11.98" />
                  <path id="path4496-8" className="cls-2" d="m130.37,26.99c11.57-2.18,26.01-1.39,37.1,8.06,11.09,9.45,19.05,24.65,21.39,37.02s-1.95,31.46-1.95,31.46c0,0-6.33-20.53-11.34-30.23-5.01-9.71-10.56-24.53-22.46-32.2-11.91-7.67-46.88-3.33-46.88-3.33,0,0,12.57-8.58,24.14-10.77h0Z" />
                  <path id="path4498-5" className="cls-2" d="m106.23,37.75c15.39-1.88,31.33-5.61,48.11.82,15.26,9.88,23.42,21.19,24.76,34l7.82,30.94c-11.15-18.11-17.16-37.68-35.3-53.79-13.02-11.63-28.25-9.8-45.38-11.98" />
                  <path id="path4502-3" className="cls-7" d="m49.27,89.05c-5.57-6.89-9.14-16.21-3.34-25.15,5.8-8.94,18.09-17.05,29.33-21.03s30.96-5.11,30.96-5.11c0,0-17.93,8.15-25.8,13.29s-20.52,11.64-24.36,20.74,10.84,30.4,10.84,30.4c0,0-12.06-6.24-17.63-13.14h0Z" />
                  <path id="path4504-3" className="cls-7" d="m66.91,102.19c-6.43-9.38-14.81-18.74-13.63-30.68,4.97-11.67,13.45-19.13,25.41-22.56l27.54-11.2c-14.14,10.72-31.25,18.48-41.36,33.24-7.33,10.61-1,19.89,2.04,31.19" />
                  <path id="path4508-8" className="cls-2" d="m126.2,20.56c10.44-5.39,24.49-8.75,37.87-2.86,13.38,5.88,25.44,18.17,31.3,29.34,5.86,11.17,7.33,30.68,7.33,30.68,0,0-12.06-17.85-19.69-25.71s-17.27-20.47-30.92-24.43c-13.64-3.95-45.86,10.18-45.86,10.18,0,0,9.53-11.81,19.97-17.19h0Z" />
                  <path id="path4510-3" className="cls-2" d="m106.23,37.75c14.18-6.19,28.36-14.31,46.3-12.93,17.5,5.11,28.61,13.61,33.64,25.49l16.53,27.4c-15.96-14.16-27.44-31.19-49.52-41.43-15.87-7.42-29.91-1.33-46.95,1.48" />
                  <path id="path4514-7" className="cls-10" d="m91.28,15.16C82.56,7.14,69.81.21,55.36,2.64c-14.45,2.43-29.25,11.57-37.76,21.18-8.51,9.61-14.9,28.63-14.9,28.63,0,0,16.21-14.67,25.6-20.54,9.39-5.87,21.93-15.95,36.14-16.4s41.79,21.83,41.79,21.83c0,0-6.22-14.17-14.95-22.19h0Z" />
                  <path id="path4516-9" className="cls-10" d="m106.23,37.35c-12.15-9.78-23.81-21.47-41.52-24.68-18.23.61-31.16,6.23-39.05,16.76L2.69,52.46c19.05-10,34.5-24,58.47-28.56,17.25-3.33,29.29,6.32,45.06,13.45" />
                  <ellipse id="path4511" className="cls-11" cx="114.15" cy="47.28" rx="6.8" ry="8.14" />
                  <path id="path4532-3" className="cls-9" d="m132.83,78.78c6.65-5.41,12.56-12.77,11.11-19.94s-8.19-13.76-15.52-17.06c-7.33-3.3-22.19-4.43-22.19-4.43,0,0,11.08,6.69,15.42,10.87,4.34,4.17,11.9,9.5,11.78,16.77-.12,7.27-18.69,24.01-18.69,24.01,0,0,11.43-4.81,18.08-10.22h.01Z" />
                  <ellipse id="ellipse4513" className="cls-11" cx="102.73" cy="48.18" rx="6.8" ry="8.14" />
                  <path id="path4534-7" className="cls-9" d="m114.75,89c8.15-7.37,17.8-14.7,20.94-24.2.12-9.33-3.9-15.35-11.98-18.23l-17.47-9.23c7.29,8.69,17.87,15.06,20.67,26.9,2.06,8.51-5.98,15.81-12.16,24.75" />
                  <path id="path4540-8" className="cls-1" d="m78.2,79.84c-2.17-3.36-3.32-8.59-.28-15.21,3.03-6.62,8.94-13.84,14.18-18.29,5.23-4.45,14.14-8.58,14.14-8.58,0,0-8.44,8.23-12.21,12.82-3.78,4.59-9.77,10.87-11.93,17.25-2.16,6.38,3.39,17.2,3.39,17.2,0,0-5.11-1.82-7.28-5.18h-.01Z" />
                  <path id="path4542-7" className="cls-1" d="m85.48,85.02c-2.43-4.77-5.74-9.19-4.63-16.89,2.79-8.19,6.96-14.34,12.49-18.57l12.9-11.81c-6.86,9.18-14.91,17.02-20.16,28.04-3.8,7.93-1.41,12.66-.59,19.22" />
                  <path id="path4707" className="cls-5" d="m52.31,260.46c4.28-44.17,8.8-88.29,20.74-130.95l-33.65,106.49-4.36,24.01,17.27.45h0Z" />
                  <ellipse id="path4837" className="cls-11" cx="106.71" cy="36.97" rx="10.12" ry="6" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
