import { ReactFragment, ReactNode, useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { Loading } from "../components/elements/Loading";
import { ConfirmPlayer } from "../logic/ConfirmPlayer";
import { useQuery } from "react-query";
import { fetchPlayerTribeData, fetchTribeData, fetchTribeInviteLink } from "../api/tribes";
import { Leaderboard } from "../components/visuals/Leaderboard";
import styled from "styled-components";
import { PageHeader } from "../components/visuals/PageHeader";
import { TabContainer } from "../components/elements/TabContainer";
import { Picks } from "../tabs/Picks";
import ShareIcon from '../assets/icons/share-arrow.svg'
import { Modal } from "../components/elements/Modal";
import { Panel } from "../components/visuals/Panel";
import { Circle } from "../components/visuals/Circle";



export function TribePage() {
    const { id } = useParams()
    const navigate = useNavigate();
    if (id === undefined) return <Navigate to='/tribes' />
    const { data: tribeData, isLoading: isTribeDataLoading } = useQuery('tribeData', () => fetchTribeData([id]));
    const { data: playerData, isLoading } = useQuery('playerTribeData', () => fetchPlayerTribeData(id));
    if (playerData === undefined || isLoading || tribeData === undefined || isTribeDataLoading || tribeData[0] === undefined) return <Loading />;


    const tabs = [
        {
            key: '1',
            label: 'Scoreboard',
            children: <Leaderboard data={playerData?.map(row => ({ name: row.display_name, score: row.score }))} />
        },
        {
            key: '2',
            label: 'Picks',
            children: <Picks tribeId={Number(id)} />
        },
    ]
    return (
        <ConfirmPlayer options={[["tribeId", id.toString()]]}>
            <PageHeader subtitle={tribeData[0]?.tribe_name.length > 10 ? true : false} title={tribeData[0]?.tribe_name || ''} />
            <Layout>
                <TabContainer tabs={tabs} labelStyles={{ borderRadius: '10px' }} />
                <Invite tribeId={id} />
                <p style={{textAlign: 'center'}}>Many improvements are coming soon. Please be patient with any bugs. If you find any bugs let me know.</p>
            </Layout>
        </ConfirmPlayer>
    )
}

function Invite({ tribeId }: { tribeId: string }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [copyClicked, setCopyClicked] = useState(false);
    const { data: link } = useQuery([tribeId], () => fetchTribeInviteLink(tribeId));

    function copyLinkToClipboard() {
        if (link !== undefined) {
            setCopyClicked(true);
            navigator.clipboard.writeText(link);
        }
    }
    return (
        <>
            <Circle color={'#235cce'} onClick={() => setModalOpen(true)}><img style={{ position: 'relative', left: '-3px' }} src={ShareIcon} /></Circle>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <InfoBox>Share this link with your friends to invite them to your tribe!</InfoBox>
                <URL>{link}</URL>
                <CopyButton onClick={() => copyLinkToClipboard()} clicked={copyClicked}>{copyClicked ? '✓' : 'Copy'}</CopyButton>
            </Modal>
        </>
    )
}


const Layout = styled.div`
    margin: auto;
    margin-top: 30px;
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: 1fr;
    width: 95%;
    border-radius: 10px;
    height: 100%;
    gap: 30px;
`
const InfoBox = styled.div`
    background-color: white;
    color: ${props => props.theme.modal.background};
    padding: 10px;
    font-size: 20px;
    width: 300px;
    text-align: center;

`
const URL = styled.div`
    background-color: white;
    color: ${props => props.theme.modal.background};
    padding: 10px;
    overflow-x: auto;
    margin-top: 15px;
    font-size: 15px;
    letter-spacing: 2px;
    width: 300px;
    white-space: nowrap;
`
const CopyButton = styled.button<{ clicked: boolean }>`
    border: none;
    padding: 8px;
    width: 300px;
    background-color: ${props => props.clicked ? '#3273f4bb' : '#235cce'};
    color: white;
    margin-top: 3px;
    font-size: 20px;
    font-weight: 700;
    cursor: ${props => props.clicked ? 'default' : 'pointer'};;
    transition: 0.2s ease-in-out;
`