import { useState, type ReactNode, type CSSProperties } from "react";
import styled from "styled-components";
import { DefaultTheme } from "styled-components";
import { Panel } from "../visuals/Panel";

type TabChild = {
  key: string;
  label: string;
  children: ReactNode;
}
export function TabContainer({ tabs, labelStyles }: { tabs: TabChild[], labelStyles?: CSSProperties }) {
  const [activeTab, setActiveTab] = useState<string>("1");
  function onTabClick(key: string) {
    setActiveTab(key);
  }

  return (
    <>
      <TabLabels tabs={tabs} onClick={onTabClick} activeTab={activeTab} styles={labelStyles} />
      {tabs.map(tab =>
        <div key={tab.key} style={{ display: activeTab === tab.key ? 'block' : 'none' }}>
          {tab.children}
        </div>
      )}
    </>
  )
}

function TabLabels({ tabs, onClick, activeTab, styles }: { tabs: TabChild[], onClick: (key: string) => void, activeTab: string, styles?: CSSProperties }) {

  return (
    <LabelContainer style={styles}>
      {tabs.map(tab =>
        <TabLabel
          width={1 / tabs.length}
          active={activeTab === tab.key}
          onClick={() => onClick(tab.key)}
          key={tab.key}
        >
          <span>{tab.label}</span>
        </TabLabel>)}
    </LabelContainer>
  );
}

const LabelContainer = styled(Panel)<{ theme: DefaultTheme }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 10px;
`
const TabLabel = styled.div<{ active?: boolean, width: number, theme: DefaultTheme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 3px 10px;
  font-size: 1.3rem;
  text-overflow: wrap;
  text-decoration: ${props => props.active ? 'underline' : 'none'};
  text-underline-offset: 10px;
  text-decoration-color: ${props => props.theme.colors.accent};
  text-decoration-thickness: 5px;
  transition: 0.3s ease-in-out;
  border-radius:inherit;
  cursor: pointer;
  
  span {
    opacity: ${props => props.active ? '1' : '0.9'};
    color: ${props => props.theme.panel.color};
  }
`