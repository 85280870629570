import { ReactNode, useEffect, useState } from "react";
import { confirmPlayer } from "../api/confirmPlayerAuth";
import { Navigate } from "react-router-dom";
import { Loading } from "../components/elements/Loading";


export interface ConfirmPlayerProps {
    children?: ReactNode;
    failedChildren?: ReactNode;
    options: [string, string][]
}

export function ConfirmPlayer({ children, options, failedChildren }: ConfirmPlayerProps) {
    const [passedTest, setPassedTest] = useState<boolean>(false);
    const [testComplete, setTestComplete] = useState<boolean>(false);
    useEffect(() => {
        async function confirmPlayerParams() {
            const passed = await confirmPlayer(options);
            setPassedTest(passed);
            setTestComplete(true);
        }
        void confirmPlayerParams();
    }, [])

    if (passedTest === false && testComplete === false) return <Loading />;
    if (passedTest === false && testComplete === true) return failedChildren !== undefined ? <>{failedChildren}</> : <Navigate to='/tribes' />

    return <>{children}</>

}