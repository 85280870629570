import { useState, createContext, useContext } from "react";

type ImageCacheContextType = {
  cache: Record<string, string>;
  addToCache: (id: string, url: string) => void;
  isCached: (id: string) => boolean;
  isFailed: (id: string) => boolean;
  addToFailedCache: (id: string) => void;
}

const ImageCacheContext = createContext<ImageCacheContextType | undefined>(undefined);

export function useImageCache() {
  const context = useContext(ImageCacheContext);
  if (!context) throw Error("useImageCache must be used within an ImageCacheProvider");

  return context;
}
export function ImageCacheProvider({ children }: { children: React.ReactNode }) {
  const [cache, setCache] = useState({});
  const [failedCache, setFailedCache] = useState<string[]>([]);

  function addToCache(id: string, url: string) {
    setCache(prev => ({ ...prev, [id]: url }))
  }

  function isCached(id: string) {
    return cache[id] !== undefined;
  }

  function isFailed(id: string) {
    return failedCache.includes(id);
  }

  function addToFailedCache(id: string) {
    setFailedCache(prev => [id, ...prev]);
  }

  return (
    <ImageCacheContext.Provider value={{ cache, addToCache, isCached, isFailed, addToFailedCache }} >
      {children}
    </ImageCacheContext.Provider>
  )
}