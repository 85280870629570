import { Route, Routes } from "react-router-dom";
import { LoginPage } from "../pages/Login";
import { ProtectedRoute } from "./ProtectedRoute";
import { HomePage } from "../pages/Home";
import { SignUp } from "../pages/SignUp";
import { MyTribesPage } from "../pages/MyTribes";
import { TribePage } from "../pages/Tribe";
import { JoinPage } from "../pages/Join";
import { StyledLink } from "../components/elements/StyledLink";
import { Castaways } from "../pages/Castaways";
import { useRole } from "../api/auth/user";
import { AdminPage } from "../pages/Admin";
import { Settings } from "../pages/Settings";


export function AppRoutes() {
    const role = useRole();
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/" element={<ProtectedRoute available={true}><HomePage /></ProtectedRoute>} />
            <Route path="/home" element={<ProtectedRoute available={true}><HomePage /></ProtectedRoute>} />
            <Route path="/tribes" element={<ProtectedRoute available={true}><MyTribesPage /></ProtectedRoute>} />
            <Route path="/tribe/:id" element={<ProtectedRoute available={true}><TribePage /></ProtectedRoute>} />
            <Route path="/join/:id" element={<ProtectedRoute available={true}><JoinPage /></ProtectedRoute>} />
            <Route path="/castaways" element={<ProtectedRoute available={true}><Castaways /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute available={true}><Settings /></ProtectedRoute>} />
            <Route path="/admin" element={<ProtectedRoute available={role === 'admin'}><AdminPage /></ProtectedRoute>} />
            { /*Page not found*/}
            <Route path="*" element={<><div>Page Not found</div><StyledLink to='/home'>Return Home</StyledLink></>} />
        </Routes>
    )
}
