import { type ReactNode, useState, useEffect, createContext, useContext } from "react";
import { DefaultTheme, ThemeProvider, createGlobalStyle } from "styled-components";

type ThemeContextType = {
  theme: DefaultTheme;
  updateTheme: (theme: string) => void;
}
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export function useTheme() {
  const theme = useContext(ThemeContext);
  if (theme === undefined) throw Error("useTheme is only available in components wrapped by the provider");
  return theme;
}

export function ThemeWrapper({ children }: { children: ReactNode }) {
  const [theme, setTheme] = useState<DefaultTheme>(defaultTheme);

  useEffect(function pickAppropriateTheme() {
    const selectedTheme = localStorage.getItem('theme');
    if (selectedTheme !== null) {
      const theme = themeMap.get(selectedTheme);
      if (theme !== undefined) {
        setTheme(theme);
        return;
      }
    }

    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    if (darkModeMediaQuery.matches) setTheme(darkModeTheme);
  }, []);

  function updateTheme(theme: string) {
    const mapValue = themeMap.get(theme);
    if (mapValue !== undefined) setTheme(mapValue);
    localStorage.setItem('theme', theme);
  }

  return (
    <>
      <ThemeContext.Provider value={{ theme, updateTheme }}>
        <ThemeProvider theme={theme}>
          <Global />
          {children}
        </ThemeProvider>
      </ThemeContext.Provider>
    </>
  )
}


const Global = createGlobalStyle<{ theme: DefaultTheme }>`
  body {
    color: ${props => props.theme.colors.main};
    background-color: ${props => props.theme.backgrounds.main};
  }
`



const defaultTheme: DefaultTheme = {
  name: 'default',
  colors: {
    main: '#50514F',
    accent: '#23CE6B',
    link: '#235cce'
  },
  backgrounds: {
    main: '#e6c8a4'
  },
  panel: {
    background: '#33312f',
    secondaryBackground: '#64615e',
    color: '#f3f1f1'
  },
  input: {
    background: '#FFFFFF',
    color: '#50514F'
  },
  modal: {
    background: '#333130',
    color: '#E1E1E1',
    frost: 'rgba(111, 111, 111, 0.7)'
  },
  format: {
    borderradius: '1rem'
  }
}

const darkModeTheme: DefaultTheme = {
  name: 'dark',
  colors: {
    main: '#E1E1E1',
    accent: '#23CE6B',
    link: '#235cce'
  },
  backgrounds: {
    main: '#121212'
  },
  panel: {
    background: '#2B2A2A',
    color: '#E1E1E1',
    secondaryBackground: '#434343'
  },
  input: {
    background: '#2B2A2A',
    color: '#E1E1E1'
  },
  modal: {
    background: '#2B2A2A',
    color: '#E1E1E1',
    frost: 'rgba(77, 77, 77, 0.7)'
  },
  format: {
    borderradius: '1rem'
  }
}

const themeMap = new Map<string, DefaultTheme>([
  ['default', defaultTheme],
  ['dark', darkModeTheme],
]); 
