import { UserLoginResponse } from '../../../../@types.d'


export async function createAccount(username: string, password: string, displayName: string): Promise<UserLoginResponse> {
    const URL = import.meta.env.VITE_API_URL;
    const requestOptions: RequestInit = {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password, displayName })
    };
    const result = await (await fetch(URL + "/auth/signup", requestOptions)).json() as UserLoginResponse
    if (result.success) localStorage.setItem('token', result.token);
    return result;
}
