import SkullCrossbones from '../../assets/icons/skull-and-crossbones-svgrepo-com.svg';
import Scroll from '../../assets/icons/scroll.svg';
import FireToken from '../../assets/icons/fire-token.svg';
import Home from '../../assets/icons/home.svg';
import Add from '../../assets/icons/add.svg';
import Torch from '../../assets/icons/torch.svg';
import Cog from '../../assets/icons/cog.svg';
import Beach from '../../assets/icons/beach.svg';


export function SkullCrossbonesIcon(props: { size: ('small' | 'medium' | 'large'), style?: React.CSSProperties }) {
  return (
    <img
      src={SkullCrossbones}
      width={`${imgSize(props.size) - 5}px`}
      alt='Skull and Crossbones'
      style={props.style}
    />
  )
}

export function ScrollIcon(props: { size: ('small' | 'medium' | 'large'), style?: React.CSSProperties }) {
  return (
    <img
      src={Scroll}
      width={`${imgSize(props.size) - 5}px`}
      alt='Scroll'
      style={props.style}
    />
  )
}

export function FireTokenIcon(props: { size: ('small' | 'medium' | 'large'), style?: React.CSSProperties }) {
  return (
    <img
      src={FireToken}
      width={`${imgSize(props.size)}px`}
      alt='Scroll'
      style={props.style}
    />
  )
}

export function Icon({ name, size }: { name: string, size: string }) {
  if (name === 'home') return <img src={Home} width={`${size}`} alt="Home Icon" />
  if (name === 'add') return <img src={Add} width={`${size}`} alt="Add Icon" />
  if (name === 'torch') return <img src={Torch} width={`${size}`} alt="Torch Icon" />
  if (name === 'cog') return <img src={Cog} width={`${size}`} alt="Cog Icon" />
  if (name === 'beach') return <img src={Beach} width={`${size}`} alt="Beach Icon" />

  return <></>;
}

function imgSize(size: ('small' | 'medium' | 'large')) {
  if (size === 'small')
    return 45
  if (size === 'medium')
    return 75
  if (size === 'large')
    return 200

  return 0;
}

export const eventIconIdMap = new Map<Number, JSX.Element>([
  [1, <SkullCrossbonesIcon size='small' />],
  [2, <ScrollIcon size='small' style={{ color: 'white' }} />],
  [3, <ScrollIcon size='small' style={{ color: 'white' }} />]
])