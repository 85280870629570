import { Navigate } from "react-router-dom";
import { useAuth } from "../authService";

type Props = {
  available: boolean;
  children: JSX.Element;
}

export function ProtectedRoute({ children, available }: Props) {
  const url = window.location.pathname;
  const { authenticated } = useAuth();
  if (!authenticated) {
    return <Navigate to={`/login?redirect=${url}`} />;
  }

  if (!available) return <Navigate to={'/home'} />;

  return children
}