import { Navigate, useNavigate, useParams } from "react-router-dom"
import { PageHeader } from "../components/visuals/PageHeader";
import { useQuery } from "react-query";
import { fetchInviteDetails, joinTribe } from "../api/join";
import { Loading } from "../components/elements/Loading";
import styled from "styled-components";
import { Button } from "../components/elements/Button";
import { Panel } from "../components/visuals/Panel";
import { useState } from "react";

export function JoinPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { data: tribeDetails, isLoading } = useQuery("tribeDetails", () => fetchInviteDetails(params?.id))
  const [loading, setLoading] = useState(false);
  if (params?.id === undefined) return <Navigate to="/tribes" />
  if (tribeDetails === undefined && !isLoading) return <Navigate to='/tribes' />;
  if (tribeDetails === undefined) return <Loading />

  async function handleClick() {
    setLoading(true);
    const result = await joinTribe(params?.id);
    if (result?.success) return navigate('/home');
    if (result?.success === false && result.error === 'User already in tribe') return navigate('/home');

    setLoading(false);
    alert("Something went wrong. Please try again later.");
  }
  return (
    <>
      <PageHeader title="Join Tribe" />
      <Layout>
        <h3><span style={{ color: '#235cce' }}>{tribeDetails.display_name}</span> has invited you to join </h3>
        <h2 style={{ color: '#235cce' }}>"{tribeDetails.tribe_name}"</h2>
        {!loading && <Button onClick={() => handleClick()}>Join?</Button>}
        {loading && <Loading />}
      </Layout>
    </>
  )
}

const Layout = styled(Panel)`
    width: 90%;
    margin: auto;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    text-align: center;
`