import styled from "styled-components";
import { Panel } from "./Panel";

interface LeaderboardRow {
    name: string;
    score: number
}
interface LeadboardProps {
    data: LeaderboardRow[];
}

export function Leaderboard({ data }: LeadboardProps) {
    if (data === undefined) return <></>;

    return (
        <LeaderboardContainer>
            {data.map((row, i) => <LeaderboardRow key={i} data={row} />)}
        </LeaderboardContainer>

    )
}

function LeaderboardRow(props: { data: LeaderboardRow }) {

    return (
        <LeaderboardRowContainer>
            <div style={{ justifyContent: 'start' }}>{props.data.name}</div>
            <div style={{ justifySelf: 'end' }}>{props.data.score}</div>
        </LeaderboardRowContainer>
    )
}

const LeaderboardContainer = styled(Panel)`
    width: 100%;
    color: rgba(111,119,122,1);
    font-size: 20px;
    margin: 0 auto;
    padding: 8px;
    min-height: 100%;
`
const LeaderboardRowContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0.7rem 2rem;
    border-top: 1px solid ${props => props.theme.colors.main};
    border-bottom: 1px solid ${props => props.theme.colors.main};
    color: ${props => props.theme.panel.color};
    :first-of-type {
        border-top: none;
    }

    :last-of-type {
        border-bottom: none;
    }

`