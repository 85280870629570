import { CSSProperties, useEffect, useRef, useState } from "react";
import styled from "styled-components";


export interface SelectProps {
    options: {
        value: string | number;
        label: string;
    }[]
    backgroundColor?: string;
    placeHolder?: string;
    value?: string | number;
    defaultValue?: string | number;
    onChange?: (value: string | number) => void;
    style?: CSSProperties;
}



export function Select({ options, placeHolder, onChange, value, style, defaultValue, backgroundColor }: SelectProps) {
    const [selectedOption, setSelectedOption] = useState<string | number | undefined>(defaultValue);
    const isInitialMount = useRef(true);
    const changedFromDefault = useRef(false);
    useEffect(() => {
        if (isInitialMount.current === true) {
            isInitialMount.current = false;
            return;
        }
        if (changedFromDefault.current === false && selectedOption === defaultValue) {
            return;
        }

        if (selectedOption !== undefined) {
            onChange && onChange(selectedOption);
            changedFromDefault.current = true;
        }
    }, [selectedOption])

    return (
      <SelectContainer style={style}>
          <StyledSelect backgroundColor={backgroundColor} value={value === undefined ? selectedOption : value} onChange={(e) => setSelectedOption(e.target.value)}>
              {options.map((option, index) => (
                  <option key={index} value={option.value}>
                      {option.label}
                  </option>
              ))}
          </StyledSelect>
          <ArrowIcon>▼</ArrowIcon>
      </SelectContainer>
  );
};

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledSelect = styled.select<{backgroundColor?: string}>`
  width: 100%;
  max-width: 100%; /* Ensure the select doesn't extend beyond its container */
  height: 40px;
  padding: 10px;
  font-size: 17px;
  border: none;
  border-radius: 4px;
  appearance: none;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : props.theme.colors.main};
  outline: none;
  text-overflow: ellipsis;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
`;

const ArrowIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: ${props => props.theme.colors.accent};
`;