import styled from 'styled-components';
import { TribalTriumphLogo } from '../components/visuals/TribalTriumphLogo';
import { TextInput } from '../components/elements/TextInput'
import { useContext, useState } from 'react';
import { attemptLogin } from '../api/auth/login';
import { useNavigate, type NavigateFunction, Link, useParams } from "react-router-dom";
import { User } from '../../../@types.d'
import { useAuth } from '../authService';
import { StyledLink } from '../components/elements/StyledLink';


export function LoginPage() {
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { login } = useAuth();

  return (
    <Layout>
      <TribalTriumphLogo size='large' style={{ gridArea: 'logo' }} />
      <Name style={{ gridArea: 'name' }}>Tribal Triumph</Name>
      {error !== '' && <span style={{ color: 'red', gridArea: 'error', textAlign: 'center' }}>{error} <br />Please try again.</span>}
      <TextInput style={{ gridArea: 'username' }} placeholder='Username' onChange={(e) => setUsername(e.target.value)} />
      <TextInput type='password' style={{ gridArea: 'password' }} placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
      <LoginButton
        style={{ gridArea: 'login' }}
        onClick={() => handleLogin(username, password, navigate, login, setError, redirect)}
        disabled={password === '' || username === ''} >
        Log In
      </LoginButton>
      <SignupDetails style={{ gridArea: 'signup' }}>New Here? <StyledLink to={redirect !== null ? `/signup?redirect=${redirect}` : `/signup`}>Sign Up</StyledLink></SignupDetails>
    </Layout>
  )
}

async function handleLogin(username: string, password: string, navigate: NavigateFunction, login: (user: User) => void, setError: React.Dispatch<React.SetStateAction<string>>, redirect: string | null) {
  const res = await attemptLogin(username, password);
  if (res.success) {
    login(res.user);
    navigate(redirect !== null ? redirect : `/home`);
  } else {
    setError(res.error || '');
  }
}


const Layout = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template:
    "logo"
    "name"
    "error"
    "username"
    "password"
    "login"
    "signup";
  grid-template-columns: 1fr;
  align-content: center;
  justify-content: center;
  justify-items: center;
  height: 100svh;
  row-gap: 10px;
`
const Name = styled.div`
  font-size: 40px;
`
const LoginButton = styled.button`
  width: 300px;
  height: 50px;
  background-color:#23CE6B;
  border: none;
  color: #FFF;
  font-size: 25px;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  
  :disabled {
    opacity: 0.7;
    background-color: #cdcdcd; 
  }
`
const SignupDetails = styled.div`
  font-size: 1.2rem;
  align-self: end;
`