import styled from "styled-components";

export const Circle = styled.button<{ color: string }>`
  border-radius: 50%;
  background-color: ${props => props.color};
  width: 50px;
  height: 50px;
  border: none;
  position: fixed;
  bottom: 40px;
  right: 5%;
  color: white;
  padding: 0;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
`

