import styled from "styled-components"
import { type ChangeEventHandler } from "react"


interface TextInputProps {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  type?: string
}

export function TextInput(props: TextInputProps ) {
  return (
    <StyledInput
      onChange={props.onChange}
      placeholder={props.placeholder}
      disabled={props.disabled}
      style={props.style}
      type={props.type || 'text'} />

  )
}

export const StyledInput = styled.input`
  width: 300px;
  height: 50px;
  border: none;
  border-radius: 3px;
  padding: 10px;
  background-color: ${props => props.theme.input.background};
  color: ${props => props.theme.input.color};
  font-size: 20px;
  outline: none;
`