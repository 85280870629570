import styled from "styled-components";
import { useAuth } from "../authService";
import { Button } from "../components/elements/Button";
import { PageHeader } from "../components/visuals/PageHeader";

export function Settings() {
  const { logout } = useAuth();
  return <> 
  <PageHeader title="Settings" />
  <Layout>
    <Button onClick={logout}>Logout</Button>
  </Layout>
  </>
}

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
`