import { AppRoutes } from './navigation/routeMap'
import { BrowserRouter, Router, RouterProvider } from 'react-router-dom'
import { ThemeWrapper } from './style/Theme'
import { ImageCacheProvider } from './context/image'


export function App() {
  return (
    <ThemeWrapper >
      <ImageCacheProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ImageCacheProvider>
    </ThemeWrapper>
  )
}