import { APIResponse, CorrectPicks, CurrentPick, NextPicks, PlayerPicks } from "../../../@types";

const URL = import.meta.env.VITE_API_URL;

export async function fetchPlayerPicks(tribeId: number) {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
    },
  };

  const result = await (await fetch(URL + `/tribes/picks/${tribeId}`, requestOptions)).json() as APIResponse;
  if (!result.success) return undefined;
  return result.data as PlayerPicks[]
}

export async function fetchCorrectPicks(tribeId: number) {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
    },
  };

  const result = await (await fetch(URL + `/tribes/picks/${tribeId}/correctPicks`, requestOptions)).json() as APIResponse;
  if (!result.success) return undefined;
  return result.data as CorrectPicks[]
}

export async function fetchNextPicks(tribeId: number) {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
    },
  };

  const result = await (await fetch(URL + `/tribes/picks/${tribeId}/next`, requestOptions)).json() as APIResponse;
  if (!result.success) return false;
  return result.data as NextPicks[]
}

export async function setPlayerPick(eventId: number, castawayId: string | number, seasonEpisodeNumber: number, tribeId: number) {
  const requestOptions: RequestInit = {
    method: 'POST',
    redirect: 'follow',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${localStorage.getItem('token') ?? ''}`
    },
    body: JSON.stringify({ eventId, castawayId, seasonEpisodeNumber })
  }

  await fetch(URL + `/tribes/picks/${tribeId}/setPick`, requestOptions)
}

export async function fetchCurrentPick(tribeId: number, seasonEpisodeNumber?: number) {
  if (seasonEpisodeNumber === undefined) return undefined;
  const requestOptions: RequestInit = {
    method: 'POST',
    redirect: 'follow',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${localStorage.getItem('token') ?? ''}`
    },
    body: JSON.stringify({ seasonEpisodeNumber })
  }

  const result = await (await fetch(URL + `/tribes/picks/${tribeId}/currentPick`, requestOptions)).json() as APIResponse;
  return result.data as CurrentPick[];
}