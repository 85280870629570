import styled from "styled-components";
import { PageHeader } from "../components/visuals/PageHeader";
import { Panel } from "../components/visuals/Panel";
import { NextPicksTile } from "../tabs/Picks";
import { useEffect, useMemo, useState } from "react";
import { Select } from "../components/elements/Select";
import { fetchNextPicks, setPlayerPick } from "../api/picks";
import { CurrentPick, NextPicks, PlayerPicks } from "../../../@types";
import { useQuery } from "react-query";
import { fetchCurrentSeasonEpisodePicks, fetchSeasonNextPicks } from "../api/seasons";
import { Loading } from "../components/elements/Loading";

export function AdminPage() {
  const { data } = useQuery("nextSeasonPicks", () => fetchSeasonNextPicks(47));
  const groupedData = useMemo(() => groupEventData(data), [data]);
  const seasonEpisodeNumber = useMemo(() => data !== undefined ? data[0]?.season_episode_number : 0, [data])
  const { data: currentPicks } = useQuery([seasonEpisodeNumber], () => fetchCurrentSeasonEpisodePicks(seasonEpisodeNumber ?? 0, 47))

  if (data === undefined || groupedData === undefined || seasonEpisodeNumber === undefined || currentPicks === undefined) return <Loading />;

  return <>
    <PageHeader title="Admin" />
    <EpisodeTileLayout>
      <EpisodeNumber style={{ padding: '10px' }}>Episode {seasonEpisodeNumber}</EpisodeNumber>
      {Object.entries(groupedData).map((event) =>
        <NextPickEventElement key={event[0]}>
          <EventName><span>{event[1].name}:</span> {event[1].description}</EventName>
          <ChooseCastaway
            currentPicks={currentPicks}
            seasonEpisodeId={seasonEpisodeNumber}
            eventId={Number(event[0])}
            castaways={event[1].castaways}
          />
        </NextPickEventElement>
      )}
    </EpisodeTileLayout>
  </>
}

type ChooseCastawayProps = {
  seasonEpisodeId: number,
  eventId: number,
  castaways: { name: string; id: number }[],
  currentPicks?: CurrentPick[],
}
function ChooseCastaway({ castaways, eventId, seasonEpisodeId, currentPicks }: ChooseCastawayProps) {
  if (castaways === undefined || castaways[0] === undefined) return <></>;
  const [currentPick, setCurrentPick] = useState<number>(castaways[0]?.id);

  useEffect(function updateCurrentPick() {
    if (currentPicks !== undefined)
      setCurrentPick(currentPicks.find(event => event.event_id === eventId)?.castaway_id || 0);
  }, [currentPicks]);

  return (
    <Select
      options={[...castaways.map(castaway => ({ label: castaway.name, value: castaway.id })), { label: 'Choose a castaway', value: 0 }]}
      onChange={(value: number) => { setCurrentPick(value) }}
      value={currentPick}
      style={{ gridColumn: '1 / -1', gridRow: 2 }}
      backgroundColor={`${currentPick === 0 ? '#7880c8' : undefined}`}
    />
  )
}

function groupEpisodeData(data: PlayerPicks[] | undefined): Record<number, PlayerPicks[]> | undefined {
  if (data === undefined) return;
  const final = data.reduce((result, row) => {
    if (result[row.season_episode_number] === undefined)
      result[row.season_episode_number] = [];

    result[row.season_episode_number].push({ ...row })
    return result;
  }, {})
  return final
}

type GroupedEventData = {
  name: string;
  description: string;
  castaways: {
    id: number;
    name: string;
  }[]
};

function groupEventData(data: NextPicks[] | undefined | false): Record<number, GroupedEventData> | undefined {
  if (data === false) return undefined;
  const final = data?.reduce((result, cur) => {
    if (result[cur.event_id] === undefined)
      result[cur.event_id] = { name: cur.event_name, castaways: [], description: cur.event_description }
    result[cur.event_id].castaways.push({ id: cur.castaway_id, name: cur.castaway_name })
    return result
  }, {});
  return final;
}

const EpisodeTileLayout = styled(Panel)`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 95%;
  margin: auto;
  padding: 0;

  :last-of-type {
    margin-bottom: 3rem;
  }
`
const EpisodeDetails = styled.div`
  background-color: ${props => props.theme.panel.background};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px 10px;
`
const EpisodeNumber = styled.h3`
  color: ${props => props.theme.panel.color};
  font-size: 22px;
  font-weight: 500;
  width: 100%;
  margin: 0;
`

const EventElement = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 10px;
  background: ${props => props.theme.panel.secondaryBackground};
  border-bottom: 2px solid ${props => props.theme.colors.main};
  color: ${props => props.theme.panel.color};

  :last-of-type {
      border-bottom: none;
      border-radius: 0 0 10px 10px;
  }
`
const NextPickEventElement = styled(EventElement)`
  grid-template-rows: 1fr 1fr;
`
const CastawayName = styled.h4`
  color: ${props => props.theme.panel.color};
  font-size: 20px;
  letter-spacing: 2px;
  grid-column: 2;
`
const EventIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  /* border-right: 2px solid ${props => props.theme.colors.main}; */
  height: 100%;
  img {
      position: relative;
      top: -1.5px;
  }
`
const EventName = styled.p`
  grid-row: 1;
  grid-column: 1 / -1;
  font-size: 0.75rem;
  justify-self: start;
  margin-left: 10px;

  span {
    color: #000000;
  }
`