import React, { useState, useEffect } from 'react';
import { useImageCache } from '../../context/image';

export function CastawayIcon({ castawayId, style, width }: { castawayId: number, style?: React.CSSProperties, width?: string }) {
  const { cache, addToCache, isCached, isFailed, addToFailedCache } = useImageCache();
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    async function loadImage() {
      if (isCached(castawayId.toString())) { setImageSrc(cache[castawayId] || ''); }
      else if (isFailed(castawayId.toString())) { setImageSrc(''); }
      else {
        try {
          const response = await fetch(`../castaways/${castawayId}.png`);
          if (response.ok) {
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);
            addToCache(castawayId.toString(), objectURL);
            setImageSrc(objectURL);
          } else {
            addToFailedCache(castawayId.toString());
          }
        } catch (e) {
          addToFailedCache(castawayId.toString());
        }
      }

    }

    loadImage();
  }, [castawayId]);

  if (imageSrc === '') return <></>;

  return (
    <img
      src={imageSrc}
      alt={'Castaway Image'}
      width={width || '70px'}
      style={{ borderRadius: '50%', ...style }}
    // onError={(e) => { e.currentTarget.style.display = 'none' }}
    />
  )
}
