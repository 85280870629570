import { createContext, useContext, useEffect, useState } from "react";
import { authenticateToken } from "./api/auth/login";
import { User } from '../../@types.d'
import { Loading } from "./components/elements/Loading";
import { Navigate, useNavigate } from "react-router-dom";

interface AuthContextType {
    authenticated: boolean;
    user: User | undefined;
    login: (user: User) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }) {
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState<User | undefined>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function initialCheck() {
            const validToken = await authenticateToken();
            if (validToken.success === true && validToken.user !== null) {
                setAuthenticated(validToken.success);
                setUser(validToken.user)
            } else {
                localStorage.removeItem('token');
            }

            setLoading(false);
        }

        initialCheck();
    }, [])

    function login(user: User) {
        setAuthenticated(true);
        setUser(user);
    }

    function logout() {
        setAuthenticated(false);
        setUser(undefined);
        localStorage.removeItem('token')
    }

    return (
        <AuthContext.Provider value={{ authenticated, user, login, logout }} >
            {!loading && children}
            {loading && <Loading />}
        </AuthContext.Provider>
    )
}

export function useAuth(): AuthContextType {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within AuthProvider');
    }

    return context;
}