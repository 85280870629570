import styled from "styled-components"

export function Loading() {
    return <Spinner>
        <Circle />
        <Circle />
        <Circle />
        <Circle />
    </Spinner>
}

const Spinner = styled.div`
    margin: auto;
    width: 40px;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 4px;
    justify-items: center;
    align-items: center;
    animation: spin 1.5s linear infinite;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
    }
`

const Circle = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 5px;
    animation: colorshift 2s linear infinite alternate;
    @keyframes colorshift {
        from {
            background-color: #23CE6B;
        }

        to {
            background-color: #23ce6a4b;
        }
    }
`