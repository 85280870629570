import { APIResponse, InviteDetails } from "../../../@types";

const URL = import.meta.env.VITE_API_URL;

export async function fetchInviteDetails(id?: string) {
    if (id === undefined) return undefined;
    const requestOptions: RequestInit = {
        method: 'GET',
        redirect: 'follow',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
        },
    };

    const result = await (await fetch(URL + `/tribes/invite/${id}`, requestOptions)).json() as APIResponse;
    if (result.success) return result.data as InviteDetails;
    return undefined;
}

export async function joinTribe(id?: string) {
    if (id === undefined) return undefined;
    const requestOptions: RequestInit = {
        method: 'POST',
        redirect: 'follow',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
        },
    };

    const result = await (await fetch(URL + `/tribes/invite/${id}/accept`, requestOptions)).json() as APIResponse;
    return result;
}