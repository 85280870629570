import styled from 'styled-components';
import { TribalTriumphLogo } from '../components/visuals/TribalTriumphLogo';
import { StyledInput, TextInput } from '../components/elements/TextInput';
import { Link, type NavigateFunction, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { createAccount } from '../api/auth/signup';
import { useAuth } from '../authService';
import { User } from '../../../@types';
import { StyledLink } from '../components/elements/StyledLink';

export function SignUp() {
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');
  const [username, setUsername] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [failedToCreate, setFailedToCreate] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(function onFailedSignup() {
    if (failedToCreate) {
      setUsername('');
      setPassword('')
    }
  }, [failedToCreate]);

  return (
    <Layout>
      <TribalTriumphLogo size='large' />
      <Name>Tribal Triumph</Name>
      {failedToCreate && <span style={{ color: '#AA0000', textAlign: 'center' }}>Invalid Username or Password. Please try again.</span>}
      <StyledInput placeholder='Username' onChange={(e) => setUsername(e.target.value)} value={username} />
      <StyledInput type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} value={password} />
      <label htmlFor='name' style={{ marginTop: '5px' }}>What name do you want to go by?</label>
      <StyledInput placeholder='Name' name='name' onChange={(e) => setDisplayName(e.target.value)} />
      <SignupButton onClick={async () => setFailedToCreate(await handleSignup({ username, password, navigate, login, displayName, redirect }))} disabled={password === '' || username === ''}>Sign Up</SignupButton>
      <SignupDetails>Already a Survivor? <StyledLink to={redirect !== null ? `/login?redirect=${redirect}` : '/login'}>Log in</StyledLink></SignupDetails>
    </Layout>
  )
}

type SignupParams = {
  username: string;
  password: string;
  displayName: string;
  navigate: NavigateFunction;
  login: (user: User) => void;
  redirect: string | null;
}
async function handleSignup(params: SignupParams) {
  const createAccountResponse = await createAccount(params.username, params.password, params.displayName);
  if (createAccountResponse.success) {
    params.login(createAccountResponse.user);
    params.navigate(params.redirect !== null ? params.redirect : '/home');
    localStorage.setItem('token', createAccountResponse.token);
    return false;
  }

  return true;
}

const Layout = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  align-content: center;
  justify-content: center;
  justify-items: center;
  height: 100svh;
  row-gap: 10px;
`
const Name = styled.div`
  font-size: 40px;
`
const SignupButton = styled.button`
  width: 300px;
  height: 50px;
  background-color:${props => props.theme.colors.accent};
  border: none;
  color: #FFF;
  font-size: 25px;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  
  :disabled {
    opacity: 0.7;
    background-color: #cdcdcd; 
  }
`
const SignupDetails = styled.div`
  font-size: 20px;
  align-self: end;
`
