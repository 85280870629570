import { APIResponse, SeasonsData, TribesData } from "../../../@types.d";

const URL = import.meta.env.VITE_API_URL;

export async function confirmPlayer(testParams: [string, string][]) {
    if (testParams === undefined || testParams.length === 0) return true;

    const requestOptions: RequestInit = {
        method: 'POST',
        redirect: 'follow',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
        },
        body: JSON.stringify({testParams})
    };

    const result = await (await fetch(URL + '/params/', requestOptions)).json() as APIResponse;

    return result.success;
}