import { UserLoginResponse } from '../../../../@types.d'
const URL = import.meta.env.VITE_API_URL;

export async function attemptLogin(username: string, password: string) {
    const requestOptions: RequestInit = {
        method: 'POST',
        redirect: 'follow',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
    };

    const result = await (await fetch(URL + "/auth/login", requestOptions)).json() as UserLoginResponse;
    if (result.success)
        localStorage.setItem('token', result.token);

    return result
}

export async function authenticateToken() {
    const token = localStorage.getItem('token');
    if (token === undefined || token === null) return { success: false, user: null };

    const requestOptions: RequestInit = {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${localStorage.getItem('token') ?? ''}`
        },
    };

    const result = await (await fetch(URL + "/auth/login/token", requestOptions)).json() as UserLoginResponse;

    return result
}