import { useEffect, useState } from "react";
import styled from "styled-components"

export function HamburgerMenu({ style, open, onClick }: { style: React.CSSProperties, open?: boolean, onClick: () => void }) {
  const [menuOpen, setMenuOpen] = useState(open || false);

  useEffect(() => {
    if (open !== undefined) setMenuOpen(open);
  }, [open])

  function onButtonClick() {
    if (onClick !== undefined) {
      onClick()
    }
    if (open === undefined) {
      setMenuOpen(v => !v);
    }
  }


  return (
    <StyledButton style={style} open={menuOpen} onClick={onButtonClick}>
      <div></div>
      <div></div>
      <div></div>
    </StyledButton>
  )
}

const StyledButton = styled.button<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 26px;
  width: 33px;
  background-color: rgba(0,0,0,0);
  border: none;
  cursor: pointer;

  div {
      height: 5px;
      width: 100%;
      background-color: ${props => props.theme.colors.main};
      transition: 0.2s ease-out;
  }

  ${props => props.open && `
    div:nth-child(1) {
      transform: translateY(10.5px) rotate(-45deg);
    }
    div:nth-child(3) {
      transform: translateY(-10.5px) rotate(45deg);
    }
    div:nth-child(2) {
      background-color: rgba(0,0,0,0);
    }
  `};

    

`
