import { useQuery } from "react-query";
import { fetchCastawayDetails } from "../api/castaways";
import { PageHeader } from "../components/visuals/PageHeader";
import { Loading } from "../components/elements/Loading";
import styled from "styled-components";
import { CastawayDetails } from "../../../@types";
import { CastawayIcon } from "../components/visuals/CastawayIcon";
import { Panel } from "../components/visuals/Panel";

export function Castaways() {
  const { data, isLoading } = useQuery('castaways', () => fetchCastawayDetails());
  if (!data || isLoading) return <Loading />;

  return (
    <>
      <PageHeader title="Castaways" />
      <Layout>
        {data.sort((a, b) => a.finished - b.finished).map((castaway: CastawayDetails, i: number) => <Card key={i} castaway={castaway} />)}
      </Layout>
    </>
  )
}

function Card({ castaway }: { castaway: CastawayDetails }) {

  return (
    <CardLayout>
      <CastawayIcon castawayId={castaway.castaway_id} width={'90px'} />
      <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'flex-start' }}>
        <Info>{castaway.name}</Info>
        <Info>Tribe: <span style={{ color: castaway.tribe_color }}>{castaway.tribe_name}</span></Info>
        {castaway.finished !== null && <Info><span style={{ color: '#dab202' }}>Eliminated Episode {castaway.finished}</span></Info>}
      </div>
    </CardLayout>
  )
}

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 90%;
  margin: auto;
  margin-top: 3rem;

  @media screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const CardLayout = styled(Panel)`
  background: ${props => props.theme.modal.background};
  color: ${props => props.theme.modal.color};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-radius: ${props => props.theme.format.borderradius};
  font-size: 0.7rem;
  padding: 0.5rem;
`

const Info = styled.p`

`