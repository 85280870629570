import { APIResponse, AiredEpisodes, NextPicks, PlayerPicks, SeasonsData, TribesData } from "../../../@types.d";

const URL = import.meta.env.VITE_API_URL;

export async function fetchPlayableSeasons() {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
    },
  };

  const result = await (await fetch(URL + '/seasons/playable', requestOptions)).json() as APIResponse;

  return result.data as SeasonsData[];
}

export async function fetchAiredEpisodes(tribeId: number) {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
    },
  };
  const result = await (await fetch(URL + `/episode/aired/${tribeId}`, requestOptions)).json() as APIResponse;

  return result.data as AiredEpisodes[];
}

export async function fetchSeasonNextPicks(season: number) {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
    },
  };
  const result = await (await fetch(URL + `/seasons/${season}/nextPicks`, requestOptions)).json() as APIResponse;

  return result.data as NextPicks[];
}

export async function fetchCurrentSeasonEpisodePicks(seasonEpisodeNumber: number, seasonId: number) {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "authorization": `Bearer ${localStorage.getItem("token") ?? ''}`
    },
  };

  const result = await (await fetch(URL + `/seasons/${seasonId}/nextPicksEntered?episode=${seasonEpisodeNumber}`, requestOptions)).json() as APIResponse;
  if (!result.success) return undefined;
  return result.data as PlayerPicks[]
}