import styled from "styled-components";
import { useTheme } from "./Theme";
import { useEffect, useState } from "react";

export function ThemeSelector() {
  const { updateTheme, theme } = useTheme();
  const [checked, setChecked] = useState(theme.name === 'dark' ? true : false);

  useEffect(() => {
    const checkboxTheme = checked ? 'dark' : 'default';
    if (checkboxTheme !== theme.name) setChecked(theme.name === 'dark' ? true : false);
  }, [theme])

  function changeTheme(value: boolean) {
    if (value) updateTheme('dark')
    else updateTheme('default');

    setChecked(value);
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
      <Title>Theme</Title>
      <Checkbox type='checkbox' id='tog' onChange={(e) => changeTheme(e.target.checked)} checked={checked} />
      <Container htmlFor="tog" >
        <Ball checked={checked}></Ball>
      </Container>
    </div>
  )
}

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  flex-shrink: 0;
  flex-grow: 0;
`
const Ball = styled.div<{ checked: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${props => props.theme.modal.color};
  z-index: 10;
  position: absolute;
  left: ${props => props.checked ? '40px' : '2px'};
  top: 2px;
  flex-shrink: 0;
  flex-grow: 0;
  transition: 0.3s ease-in-out;
`
const Container = styled.label`
  display: inline-block;
  width: 70px;
  height: 32px;
  border-radius: 50px;
  background-color: ${props => props.theme.modal.frost};
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
`

const Title = styled.h3`
   display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  color: ${props => props.theme.panel.color};
  margin: 0.3rem 0;
  padding: 0 0.8rem;
  font-size: 1.3rem;
  width: 100%;
  text-align: center;
  transition: 0.2s ease-in-out;
  :hover {
    background-color: ${props => props.theme.panel.secondaryBackground};
  }
`