import styled from "styled-components"
import { ThemeSelector } from "../../style/ThemeSelector"
import { StyledLink } from "./StyledLink"
import { Link } from "react-router-dom"
import { Icon } from "../visuals/GameIcons"
import { useAuth } from "../../authService"
import { useRole } from "../../api/auth/user"

export function SideMenu({ open, onClose }: { open: boolean, onClose: () => void }) {
  const role = useRole();

  return (
    <>
      <Frost open={open} onClick={() => onClose()} />
      <Layout open={open}>
        <Navigation />
        <User role={role} />
        <ThemeSelector />
      </Layout>
    </>
  )
}

function User({ role }: { role?: string }) {
  return (
    <UserLayout>
      <h3 style={{ textAlign: 'left', opacity: 0.6 }}>User</h3>
      <StyledNavLink to='/settings' >
        <Icon name='cog' size='30px' />
        Settings
      </StyledNavLink>
      {role === 'admin' && <StyledNavLink to='/admin' >
        <Icon name='cog' size='30px' />
        Admin
      </StyledNavLink>}
    </UserLayout>
  )
}

function Navigation() {
  return (
    <NavContainer>
      <h3 style={{ textAlign: 'left', opacity: 0.6 }}>Navigation</h3>
      <StyledNavLink to='/home'><Icon name='home' size={`25px`} />Home</StyledNavLink>
      <StyledNavLink to='/tribes'><Icon name='torch' size={`25px`} />Tribes</StyledNavLink>
      <StyledNavLink to='/castaways'><Icon name='beach' size={`25px`} />Castaways</StyledNavLink>
    </NavContainer>
  )
}

const Layout = styled.aside<{ open: boolean }> `
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto auto;
  flex-direction: column;
  align-items: start;
  height: 100dvh;
  width: fit-content;
  max-width: 70vw;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: ${props => `${props.theme.format.borderradius} 0 0 ${props.theme.format.borderradius}`};
  background-color: ${props => props.theme.panel.background};
  position: fixed;
  right: ${props => props.open ? '0px' : '-100%'};
  top:0;
  transition: 0.3s ease-in-out;
  z-index: 5;
  overflow: hidden;

  nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
`
const Frost = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
  opacity: ${props => props.open ? 1 : 0};
  pointer-events: ${props => props.open ? 'auto' : 'none'};
  background-color: ${props => props.theme.modal.frost};
  transition: opacity 0.3s ease-in-out;
  z-index: 4;
`
const NavContainer = styled.nav`
  h3 {
    color: ${props => props.theme.modal.color};
    opacity: 0.95;
  }
`
const StyledNavLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  color: ${props => props.theme.panel.color};
  margin: 0.3rem 0;
  padding: 0 0.8rem;
  font-size: 1.3rem;
  width: 100%;
  text-align: center;
  transition: 0.2s ease-in-out;
  :hover {
    background-color: ${props => props.theme.panel.secondaryBackground};
  }
`
const UserLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: ${props => props.theme.panel.color};
`